// @flow
import dayjs from 'dayjs'
import * as React from 'react'

import { Label } from 'components/common/label/label.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis } from 'components/styled/text'

import {
  EventRowContent,
  EventRowDetails,
  EventRowDetailsDay,
  EventRowContentInformation,
  EventRowTooltipDay,
  EventRowTooltipDayAt,
  EventRowTooltipDescription,
  EventRowDetailsSource,
  EventTooltipContainer,
} from '../event-row/event-row.styles'
import { APIIcon } from '../icons'
import { Timeline } from '../timeline/timeline'

import { convertAttributesToJson } from 'com.batch/profile/infra/formats/convert-attributes-to-json'
import { type CustomEventRecord } from 'com.batch/profile/models/profile.records'
import { EventRowAttributes } from 'com.batch/profile/ui/components/events-block/event-row/event-row-attributes'

type EventRowProps = {
  event: CustomEventRecord,
  ...
}

const platform = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
}

export const EventRow = ({ event }: EventRowProps): React.Node => {
  const { attributes } = event
  const hasPropertiesOrLabel = React.useMemo(
    () => attributes.size !== 0 || event.label !== '',
    [attributes.size, event.label]
  )

  convertAttributesToJson(attributes)

  const trackedSrc = React.useMemo(() => {
    return event.platform === 'IOS'
      ? 'iOS app'
      : event.platform === 'ANDROID'
        ? 'Android app'
        : 'website'
  }, [event.platform])

  const isSdkEvent = React.useMemo(() => {
    return (
      event.source === 'ANDROID' ||
      event.source === 'IOS' ||
      event.source === 'WEBPUSH' ||
      event.source === 'SDK'
    )
  }, [event.source])

  const trackedDescription = React.useMemo(() => {
    if (isSdkEvent) return `Tracked in the ${trackedSrc}`
    if (event.source === 'PROFILE_API') return 'Tracked with the Profile API'
    return 'Tracked with the Trigger Events API'
  }, [isSdkEvent, event.source, trackedSrc])

  const sendDateUtc = React.useMemo(() => {
    return dayjs.utc(event.sendDate)
  }, [event.sendDate])

  const sendDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])

  return (
    <EventRowContent>
      <Timeline />
      <EventRowContentInformation expanded={hasPropertiesOrLabel}>
        <EventRowDetails>
          <Ellipsis>{event.name}</Ellipsis>
          <Tooltip
            minWidth={300}
            placement="bottom"
            tooltip={
              <EventTooltipContainer>
                <EventRowTooltipDay>{sendDateUtc.format('ddd Do MMMM')}</EventRowTooltipDay>
                <EventRowTooltipDayAt> at </EventRowTooltipDayAt>
                <EventRowTooltipDay>{sendDateUtc.format('HH:mm')}</EventRowTooltipDay>
                <Label className="tz" variant="contrast">
                  UTC
                </Label>
                <EventRowTooltipDescription>{trackedDescription}</EventRowTooltipDescription>
              </EventTooltipContainer>
            }
          >
            <EventRowDetailsSource>
              <EventRowDetailsDay>{sendDate.format('HH:mm')}</EventRowDetailsDay>
              {isSdkEvent ? (
                <Icon icon={platform[event.platform].icon} style={{ marginLeft: 6 }} />
              ) : (
                <APIIcon />
              )}
            </EventRowDetailsSource>
          </Tooltip>
        </EventRowDetails>
        <div>
          {event.label !== '' && <Label className="fs-exclude">{event.label}</Label>}
          {event.attributes.size > 0 && (
            <EventRowAttributes
              hasLabel={event.label !== ''}
              attributes={event.attributes}
              eventName={event.name}
            />
          )}
        </div>
      </EventRowContentInformation>
    </EventRowContent>
  )
}
